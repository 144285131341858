import { FormHeader } from "./components/FormHeader.jsx";
import { AnpdForm } from "./components/AnpdForm.jsx";
import styles from './css/app.module.css'

export default function App() {
  return (
    <div className={styles.body}>
      <FormHeader/>
      <AnpdForm/>
    </div>
  );
}

