import styles from '../css/form.module.css'
import FormFields from './FormFields.jsx'

export const AnpdForm = () => {
  return(
    <div className={styles.body}>
      <FormFields/>
    </div>
  )
}

