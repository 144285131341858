
import styles from '../css/formheader.module.css'
import logo from '../images/anpd-logo.png'

export const FormHeader = () => {
  return(
    <div className={styles.header}>
      <div className={styles.imageBox}>
        <img src={logo} alt="ANPD" className={styles.logo}/>
      </div>
      <div className={styles.titleBox}>
        <h1 className={styles.h1}>Formulário de Comunicação de Incidente de Segurança com Dados Pessoais</h1>
      </div>
    </div>
  )
}

