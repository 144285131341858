import React from 'react';
import styles from '../css/formfields.module.css';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const FormFields = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const input = document.getElementById('form-to-print');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgWidth = 220;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("formularioANPD.pdf");
      })
      .catch((err) => {
        console.error('Error generating PDF:', err);
      });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit} id='form-to-print'>
      <h2>Dados do Controlador</h2>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Razão Social / Nome:</label>
        <input name="socialName" type="text" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>CNPJ/CPF:</label>
        <input name="cnpjCpf" type="text" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Endereço:</label>
        <input name="address" type="text" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Cidade:</label>
        <input name="city" type="text" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Estado:</label>
        <input name="state" type="text" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>CEP:</label>
        <input name="cep" type="text" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Telefone:</label>
        <input name="phone" type="text" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>E-mail:</label>
        <input name="email" type="email" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Declara ser Microempresa ou Empresa de Pequeno Porte:</label>

        <div className={styles.radioGroup}>
          <input name="isMicroCompany" type="radio" value="Sim" className={styles.input} required />
          <span>Sim</span>

          <span className={styles.radioSeparator}></span>

          <input name="isMicroCompany" type="radio" value="Não" className={styles.input} required />
          <span>Não</span>
        </div>
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Declara ser Agente de Tratamento de Pequeno Porte:</label>

        <div className={styles.radioGroup}>
          <input name="isSmallAgent" type="radio" value="Sim" className={styles.input} required />
          <span>Sim</span>

          <span className={styles.radioSeparator}></span>

          <input name="isSmallAgent" type="radio" value="Não" className={styles.input} required />
          <span>Não</span>
        </div>
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Informe o número aproximado de titulares cujos dados são tratados por sua organização:</label>
        <input name="dataSubjects" type="number" className={styles.input} required />
      </div>

      <h2>Dados do Encarregado pelo Tratamento de Dados Pessoais</h2>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Possui um encarregado pelo tratamento de dados pessoais?</label>

        <div className={styles.radioGroup}>
          <input name="hasDataHandler" type="radio" value="Sim" required />
          <span>Sim</span>

          <span className={styles.radioSeparator}></span>

          <input name="hasDataHandler" type="radio" value="Não" required />
          <span>Não</span>

        </div>
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Nome:</label>
        <input name="handlerName" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>CNPJ/CPF:</label>
        <input name="handlerCpf" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Telefone:</label>
        <input name="handlerPhone" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>E-mail:</label>
        <input name="handlerEmail" type="email" className={styles.input} />
      </div>

      <h2>Dados do Notificante / Representante Legal</h2>

      <div className={styles.radioFullRow}>
        <input name="legalRepresentativeData" type="radio" value="noLegalRepresentative" required />
        <span>O próprio encarregado pela proteção de dados.</span>
      </div>

      <div className={styles.radioFullRow}>
        <input name="legalRepresentativeData" type="radio" value="hasLegalRepresentative" required />
        <span>Outros (especifique):</span>
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Nome:</label>
        <input name="legalRepresentativeName" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>CNPJ/CPF:</label>
        <input name="legalRepresentativeCpf" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Telefone:</label>
        <input name="legalRepresentativePhone" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>E-mail:</label>
        <input name="legalRepresentativeEmail" type="email" className={styles.input} />
      </div>


      <div className={styles.advice}>
        <p>
          A documentação comprobatória da legitimidade para representação do controlador junto à ANPD deve ser protocolada em
          conjunto com o formulário de comunicação de incidente.<br />
          Exemplos:
          <ul>
            <li>Encarregado: ato de designação/nomeação/procuração.</li>
            <li>Representante: ato constitutivo (contrato/estatuto social) e procuração, se cabível.</li>
          </ul>
        </p>
      </div>

      <blockquote>
        <sup>1</sup> Nos termos do REGULAMENTO DE APLICAÇÃO DA LEI Nº 13.709, DE 14 DE AGOSTO DE 2018, aprovado pela RESOLUÇÃO CD/ANPD Nº 2, DE 27 DE JANEIRO DE 2022.
        (
        <a href="https://www.in.gov.br/en/web/dou/-/resolucao-cd/anpd-n-2-de-27-de-janeiro-de-2022-376562019" target='blank'>
          https://www.in.gov.br/en/web/dou/-/resolucao-cd/anpd-n-2-de-27-de-janeiro-de-2022-376562019
        </a>
        )
      </blockquote>

      <h2>Tipo de Comunicação</h2>

      <div className={styles.comunicationType}>
        <div className={styles.comunicationTypeRadio}>
          <input name="comunicationType" type="radio" value="completa" required />
          <span>Completa</span>
        </div>
        <p className={styles.comunicationTypeDesc}>Todas as informações a respeito do incidente estão disponíveis e <strong>a comunicação aos titulares já foi realizadas</strong></p>
      </div>

      <div className={styles.comunicationType}>
        <div className={styles.comunicationTypeRadio}>
          <input name="comunicationType" type="radio" value="preliminar" required />
          <span>Preliminar</span>
        </div>
        <p className={styles.comunicationTypeDesc}>
          Nem todas as informações sobre o incidente estão disponíveis, justificadamente, ou <strong>a comunicação aos titulares ainda não foi realizada</strong>.
          A complementação deverá ser encaminhada em até <strong>30 dias corridos</strong> da comunicação preliminar.
        </p>
      </div>

      <div className={styles.comunicationType}>
        <div className={styles.comunicationTypeRadio}>
          <input name="comunicationType" type="radio" value="complementar" required />
          <span>Complementar</span>
        </div>
        <p className={styles.comunicationTypeDesc}>
          Complementação de informações prestadas em comunicação preliminar. <strong>A comunicação complementar deve ser protocolada no mesmo processo que a
            comunicação preliminar</strong>
        </p>
      </div>

      <div className={styles.advice}>
        <p>
          &#10147; A comunicação preliminar é insuficiente para o cumprimento da obrigação estabelecida pelo art. 48 da LGPD e deve ser complementada
          pelo controlador no prazo estabelecido.
        </p>
      </div>

      <h2>Avaliação de Risco do Incidente</h2>

      <div className={styles.radioFullRow}>
        <input name="incidentRiskReview" type="radio" value="O incidente de segurança pode acarretar risco ou dano relevante aos titulares" required />
        <span>O incidente de segurança pode acarretar risco ou dano relevante aos titulares</span>
      </div>

      <div className={styles.radioFullRow}>
        <input name="incidentRiskReview" type="radio" value="O incidente não acarretou risco ou dano relevante aos titulares." required />
        <span>O incidente não acarretou risco ou dano relevante aos titulares. <strong>(Comunicação Complementar)</strong></span>
      </div>

      <div className={styles.radioFullRow}>
        <input name="incidentRiskReview" type="radio" value="O risco do incidente aos titulares ainda está sendo apurado." required />
        <span>O risco do incidente aos titulares ainda está sendo apurado.	     <strong>(Comunicação Preliminar)</strong></span>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Justifique, se cabível, a avaliação do risco do incidente:</strong></label>
        <textarea name="incidentRiskReviewDescription" type="text" className={styles.textAreaInput} />
      </div>

      <h2>Da Ciência da Ocorrência do Incidente</h2>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Por qual meio se tomou conhecimento do incidente?</strong></label>

        <div className={styles.radioRow}>
          <div className={styles.radioRowBox}>
            <input name="incidentDiscover" type="radio" value="Identificado pelo próprio controlador." required />
            <span>Identificado pelo próprio controlador.</span>
          </div>

          <div className={styles.radioRowBox}>
            <input name="incidentDiscover" type="radio" value="Notificação do operador de dados." required />
            <span>Notificação do operador de dados.</span>
          </div>

          <div className={styles.radioRowBox}>
            <input name="incidentDiscover" type="radio" value="Denúncia de titulares/terceiros." required />
            <span>Denúncia de titulares/terceiros.</span>
          </div>
        </div>

        <div className={styles.radioRow}>
          <div className={styles.radioRowBox}>
            <input name="incidentDiscover" type="radio" value="Notícias ou redes sociais" required />
            <span>Notícias ou redes sociais</span>
          </div>

          <div className={styles.radioRowBox}>
            <input name="incidentDiscover" type="radio" value="Notificação da ANPD." required />
            <span>Notificação da ANPD.</span>
          </div>

          <div className={styles.radioRowBox}>
            <input name="incidentDiscover" type="radio" value="Outro" required />
            <span>Outro:</span>
            <input name="incidentDiscoverReason" type="text" className={styles.input} />
          </div>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Descreva, resumidamente, de que forma a ocorrência do incidente foi conhecida:</strong></label>
        <textarea name="incidentDiscoverReasonDescription" type="text" className={styles.textAreaInput} required />
      </div>

      <label className={styles.textAreaLabel}><strong>Caso o incidente tenha sido comunicado ao controlador por um operador, informe:</strong></label>
      <label className={styles.textAreaLabel}><strong>Dados do Operador:</strong></label>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Razão Social / Nome:</label>
        <input name="operatorSocialName" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>CNPJ/CPF:</label>
        <input name="operatorCnpjCpf" type="text" className={styles.input} />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>E-mail:</label>
        <input name="operatorEmail" type="email" className={styles.input} />
      </div>

      <div className={styles.advice}>
        <p>Cabe ao controlador solicitar ao operador as informações necessárias à comunicação do incidente</p>
      </div>

      <h2>Da Tempestividade da Comunicação do Incidente</h2>
      <label className={styles.textAreaLabel}><strong>Informe as seguintes datas, sobre o incidente:</strong></label>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Quando ocorreu</label>
        <input name="ocorrencyWhen" type="date" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Quando tomou ciência</label>
        <input name="ocorrencyWhoDiscover" type="date" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Quando comunicou à ANPD</label>
        <input name="ocorrencyWhoANPD" type="date" className={styles.input} required />
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Quando comunicou aos titulares	</label>
        <input name="ocorrencyWhoTell" type="date" className={styles.input} required />
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}>
          <strong>Justifique, se cabível, a não realização da comunicação completa à ANPD e aos titulares de dados afetados no prazo sugerido de 2
            (dois) dias úteis após a ciência do incidente:
          </strong>
        </label>
        <textarea name="anpdReport" type="text" className={styles.textAreaInput} />
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se cabível, informe quando e a quais outras autoridades o incidente foi comunicado:</strong></label>
        <textarea name="anpdReport2" type="text" className={styles.textAreaInput} />
      </div>

      <h2>Da Comunicação do Incidente aos Titulares dos Dados</h2>
      <label className={styles.textAreaLabel}><strong> Os titulares dos dados afetados foram comunicados sobre o incidente?</strong></label>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentWasReported" type="radio" value="Sim" required />
          <span>Sim</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentWasReported" type="radio" value="Não, por não haver risco ou dano relevante a eles." required />
          <span>Não, por não haver risco ou dano relevante a eles.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentWasReported" type="radio" value="Não, mas o processo de comunicação está em andamento." required />
          <span>Não, mas o processo de comunicação está em andamento.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentWasReported" type="radio" value="Não, vez que o risco do incidente ainda está sendo apurado" required />
          <span>Não, vez que o risco do incidente ainda está sendo apurado. <strong>(comunicação preliminar)</strong></span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se cabível, quando os titulares serão comunicados sobre o incidente?</strong></label>
        <textarea name="whenReport" type="text" className={styles.textAreaInput} />
      </div>

      <label className={styles.textAreaLabel}><strong>De que forma a ocorrência do incidente foi comunicada aos titulares?</strong></label>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="howIsReported" type="radio" value="Comunicado individual por escrito.(mensagem eletrônica / carta / e-mail / etc.)" required />
          <span>Comunicado individual por escrito.(mensagem eletrônica / carta / e-mail / etc.)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="howIsReported" type="radio" value="Anúncio público no sítio eletrônico, mídias sociais ou aplicativos do controlador" required />
          <span>Anúncio público no sítio eletrônico, mídias sociais ou aplicativos do controlador</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="howIsReported" type="radio" value="Comunicado individual por escrito com confirmação de recebimento.(mensagem eletrônica / carta / e-mail / etc.)" required />
          <span>Comunicado individual por escrito com confirmação de recebimento.(mensagem eletrônica / carta / e-mail / etc.)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="howIsReported" type="radio" value="Ampla divulgação do fato em meios de comunicação, por iniciativa do controlador. (especifique abaixo)" required />
          <span>Ampla divulgação do fato em meios de comunicação, por iniciativa do controlador. (especifique abaixo)</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="howIsReported" type="radio" value="Outra" required />
          <span>Outra: </span>
          <input name="howIsReportedDescr" type="text" className={styles.input} />
        </div>

        <div className={styles.radioRowBox}>
          <input name="howIsReported" type="radio" value="Não se aplica" required />
          <span>Não se aplica</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Descreva como ocorreu a comunicação:</strong></label>
        <textarea name="howIsReportedDescr" type="text" className={styles.textAreaInput} required />
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Quantos titulares foram comunicados individualmente sobre o incidente?</strong></label>
        <textarea name="individualReports" type="text" className={styles.textAreaInput} required />
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Justifique, se cabível, o que motivou a não realização da comunicação individual aos titulares:</strong></label>
        <textarea name="whyNotIndividualReport" type="text" className={styles.textAreaInput} />
      </div>

      <div className={styles.advice}>
        <p>
          O comunicado aos titulares deve utilizar linguagem clara e conter, ao menos, as seguintes informações:
        </p>
        <ol>
          <li>resumo e data de ocorrência do incidente;</li>
          <li>descrição dos dados pessoais afetados;</li>
          <li>riscos e consequências aos titulares de dados;</li>
          <li>medidas tomadas e recomendadas para mitigar seus efeitos, se cabíveis;</li>
          <li>dados de contato do controlador para obtenção de informações adicionais sobre o incidente.</li>
        </ol>
      </div>

      <label className={styles.textAreaLabel}><strong>O comunicado aos titulares atendeu os requisitos acima?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="isReportedRight" type="radio" value="Sim" required />
          <span>Sim</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="isReportedRight" type="radio" value="Não" required />
          <span>Não</span>
        </div>
      </div>

      <div className={styles.advice}>
        <p>&#10147; Se não atendidos os requisitos, o comunicado aos titulares deverá ser devidamente retificado</p>
        <p>&#10147; Poderá ser solicitada pela ANPD, a qualquer tempo, cópia do comunicado aos titulares para fins de fiscalização.</p>
      </div>

      <h2>Descrição do Incidente</h2>
      <label className={styles.textAreaLabel}><strong>Qual o tipo de incidente? (Informe o tipo mais específico)</strong></label>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Sequestro de Dados (ransomware) sem transferência de informações." required />
          <span>Sequestro de Dados (ransomware) sem transferência de informações.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Sequestro de dados (ransomware) com transferência e/ou publicação de informações." required />
          <span>Sequestro de dados (ransomware) com transferência e/ou publicação de informações.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Exploração de vulnerabilidade em sistemas de informação." required />
          <span>Exploração de vulnerabilidade em sistemas de informação.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Vírus de Computador / Malware." required />
          <span>Vírus de Computador / Malware.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Roubo de credenciais / Engenharia Social." required />
          <span>Roubo de credenciais / Engenharia Social.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Violação de credencial por força bruta" required />
          <span>Violação de credencial por força bruta</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Publicação não intencional de dados pessoais" required />
          <span>Publicação não intencional de dados pessoais</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Divulgação indevida de dados pessoais." required />
          <span>Divulgação indevida de dados pessoais.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Envio de dados a destinatário incorreto." required />
          <span>Envio de dados a destinatário incorreto.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Acesso não autorizado a sistemas de informação" required />
          <span>Acesso não autorizado a sistemas de informação</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Negação de Serviço (DoS)." required />
          <span>Negação de Serviço (DoS).</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Alteração/exclusão não autorizada de dados." required />
          <span>Alteração/exclusão não autorizada de dados.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Perda/roubo de documentos ou dispositivos eletrônicos." required />
          <span>Perda/roubo de documentos ou dispositivos eletrônicos.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Descarte incorreto de documentos ou dispositivos eletrônicos." required />
          <span>Descarte incorreto de documentos ou dispositivos eletrônicos.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Falha em equipamento (hardware)." required />
          <span>Falha em equipamento (hardware)..</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Falha em sistema de informação (software)." required />
          <span>Falha em sistema de informação (software).</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="outro tipo de incidente cibernético." required />
          <span>Outro tipo de incidente cibernético.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="incidentType" type="radio" value="Outro tipo de incidente não cibernético" required />
          <span>Outro tipo de incidente não cibernético.</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Descreva, resumidamente, como ocorreu o incidente:</strong></label>
        <textarea name="incidentDescr" type="text" className={styles.textAreaInput} required />
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Explique, resumidamente, por que o incidente ocorreu (identifique a causa raiz, se conhecida):</strong></label>
        <textarea name="whyIncidentHappens" type="text" className={styles.textAreaInput} required />
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Que medidas foram adotadas para corrigir as causas do incidente?</strong></label>
        <textarea name="howIncidenteFixed" type="text" className={styles.textAreaInput} required />
      </div>

      <h2>Impactos do Incidente Sobre os Dados Pessoais</h2>
      <label className={styles.textAreaLabel}><strong>De que formas o incidente afetou os dados pessoais:</strong></label>

      <div className={styles.comunicationType}>
        <div className={styles.comunicationTypeRadio}>
          <input name="incidentImpacts" type="checkbox" value="Confidencialidade"  />
          <span>Confidencialidade</span>
        </div>
        <p className={styles.comunicationTypeDesc}>Houve acesso não autorizado aos dados, violando seu sigilo.</p>
      </div>

      <div className={styles.comunicationType}>
        <div className={styles.comunicationTypeRadio}>
          <input name="incidentImpacts" type="checkbox" value="Integridade"  />
          <span>Integridade</span>
        </div>
        <p className={styles.comunicationTypeDesc}>Houve alteração ou destruição de dados de maneira não autorizada ou acidental.</p>
      </div>

      <div className={styles.comunicationType}>
        <div className={styles.comunicationTypeRadio}>
          <input name="incidentImpacts" type="checkbox" value="Disponibilidade"  />
          <span>Disponibilidade</span>
        </div>
        <p className={styles.comunicationTypeDesc}>Houve perda ou dificuldade de acesso aos dados por período significativo.</p>
      </div>

      <label className={styles.textAreaLabel}><strong>Se aplicável, quais os tipos de dados pessoais sensíveis foram violados?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Origem racial ou étnica"  />
          <span>Origem racial ou étnica</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Convicção religiosa." />
          <span>Convicção religiosa.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Opinião política" />
          <span>Opinião política</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Referente à saúde" />
          <span>Referente à saúde</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Biométrico" />
          <span>Biométrico</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Genético" />
          <span>Genético</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Referente à vida sexual." />
          <span>Referente à vida sexual.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataExposedType" type="checkbox" value="Filiação a organização sindical, religiosa, filosófica ou política" />
          <span>Filiação a organização sindical, religiosa, filosófica ou política</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se aplicável, descreva os tipos de dados pessoais sensíveis violados:</strong></label>
        <textarea name="dataExposed" type="text" className={styles.textAreaInput} />
      </div>

      <label className={styles.textAreaLabel}><strong>Quais os demais tipos de dados pessoais violados?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Dados básicos de identificação(ex: nome, sobrenome, data de nascimento, matrícula)" />
          <span>Dados básicos de identificação(ex: nome, sobrenome, data de nascimento, matrícula)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Número de documentos de identificação oficial.(ex: RG, CPF, CNH, passaporte)" />
          <span>Número de documentos de identificação oficial.(ex: RG, CPF, CNH, passaporte)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Dados de contato.(ex: telefone, endereço, e-mail)" />
          <span>Dados de contato.(ex: telefone, endereço, e-mail)</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Dados de meios de pagamento. (ex: cartão de crédito/débito)" />
          <span>Dados de meios de pagamento. (ex: cartão de crédito/débito)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Cópias de documentos de identificação oficial." />
          <span>Cópias de documentos de identificação oficial.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Dados protegidos por sigilo profissional/legal." />
          <span>Dados protegidos por sigilo profissional/legal.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Dado financeiro ou econômico"  />
          <span>Dado financeiro ou econômico</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Nomes de usuário de sistemas de informação" />
          <span>Nomes de usuário de sistemas de informação</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Dado de autenticação de sistema. (ex: senhas, PIN ou tokens)" />
          <span>Dado de autenticação de sistema. (ex: senhas, PIN ou tokens)</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Imagens / Áudio / Vídeo" />
          <span>Imagens / Áudio / Vídeo</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Dado de geolocalização.(ex: coordenadas geográficas)" />
          <span>Dado de geolocalização.(ex: coordenadas geográficas)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="dataPlusExposedType" type="checkbox" value="Outros" />
          <span>Outros</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Descreva os tipos de dados pessoais não sensíveis violados, se cabível:</strong></label>
        <textarea name="dataPlusExposed" type="text" className={styles.textAreaInput} />
      </div>

      <h2>Riscos e Consequências aos Titulares dos Dados</h2>
      <label className={styles.textAreaLabel}><strong>Foi elaborado um Relatório de Impacto à Proteção de Dados Pessoais (RIPD) das atividades de tratamento afetadas pelo incidente?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="hasReport" type="radio" value="Sim" required />
          <span>Sim</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="hasReport" type="radio" value="Não" required />
          <span>Não</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Qual a quantidade total de titulares cujos dados são tratados nas atividades afetadas pelo incidente?</strong></label>
        <textarea name="howMuchAffected" type="text" className={styles.textAreaInput} required/>
      </div>

      <label className={styles.textAreaLabel}><strong>Qual a quantidade aproximada de titulares afetados  pelo incidente?</strong></label>
      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Total de titulares afetados</label>
        <input name="howMuchAffectedInput" type="text" className={styles.input} required/>
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Crianças e/ou adolescentes</label>
        <input name="howMuchAffectedKidsAndTeens" type="text" className={styles.input} required/>
      </div>

      <div className={styles.inputBox}>
        <label className={styles.inputLabel}>Outros titulares vulneráveis</label>
        <input name="legalRepresentativeOthers" type="text" className={styles.input} required/>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se aplicável, descreva as categorias de titulares vulneráveis afetados:</strong></label>
        <textarea name="howMuchAffected" type="text" className={styles.textAreaInput} />
      </div>

      <label className={styles.textAreaLabel}><strong>Quais as categorias de titulares foram afetadas pelo incidente?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Funcionários" />
          <span>Funcionários</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Prestadores de serviços." />
          <span>Prestadores de serviços.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Estudantes/Alunos." />
          <span>Estudantes/Alunos.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Clientes/Cidadãos." />
          <span>Clientes/Cidadãos.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Usuários" />
          <span>Usuários</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Inscritos/Filiados." />
          <span>Inscritos/Filiados.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Pacientes de serviço de saúde" />
          <span>Pacientes de serviço de saúde</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Ainda não identificadas." />
          <span>Ainda não identificadas.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="exposedPeopleCategory" type="checkbox" value="Outras:" />
          <span>Outras: </span>
          <input name="exposedPeopleCategory" type="text" className={styles.input} />
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Informe a quantidade de titulares afetados, por categoria:</strong></label>
        <textarea name="howMuchAffected" type="text" className={styles.textAreaInput} required/>
      </div>

      <label className={styles.textAreaLabel}><strong>Quais as prováveis consequências do incidente para os titulares?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Danos morais" />
          <span>Danos morais.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Danos materiais" />
          <span>Danos materiais.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Violação à integridade física" />
          <span>Violação à integridade física</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Discriminação social." />
          <span>Discriminação social.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Danos reputacionais." />
          <span>Danos reputacionais.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Roubo de identidade." />
          <span>Roubo de identidade.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Engenharia social / Fraudes." />
          <span>Engenharia social / Fraudes.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Limitação de acesso a um serviço." />
          <span>Limitação de acesso a um serviço.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Exposição de dados protegidos por sigilo profissional/legal." />
          <span>Exposição de dados protegidos por sigilo profissional/legal.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Restrições de direitos." />
          <span>Restrições de direitos.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Perda de acesso a dados pessoais." />
          <span>Perda de acesso a dados pessoais.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="consequences" type="checkbox" value="Outras" />
          <span>Outras: </span>
          <input name="consequences" type="text" className={styles.input} />
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se cabível, descreva as prováveis consequências do incidente para cada grupo de titulares:</strong></label>
        <textarea name="consequencesDescribe" type="text" className={styles.textAreaInput} />
      </div>

      <label className={styles.textAreaLabel}><strong>Qual o provável impacto do incidente sobre os titulares? (admite só uma marcação)</strong></label>
      <div className={styles.radioFullRow}>
        <input name="consequencesImpacts" type="radio" value="Podem não sofrer danos, sofrer danos negligenciáveis ou superáveis sem dificuldade" required />
        <span>Podem não sofrer danos, sofrer danos negligenciáveis ou superáveis sem dificuldade.</span>
      </div>

      <div className={styles.radioFullRow}>
        <input name="consequencesImpacts" type="radio" value="Podem sofrer danos, superáveis com certa dificuldade." required />
        <span>Podem sofrer danos, superáveis com certa dificuldade.</span>
      </div>

      <div className={styles.radioFullRow}>
        <input name="consequencesImpacts" type="radio" value="Podem sofrer danos importantes, superáveis com muita dificuldade." required />
        <span>Podem sofrer danos importantes, superáveis com muita dificuldade.</span>
      </div>

      <div className={styles.radioFullRow}>
        <input name="consequencesImpacts" type="radio" value=" Podem sofrer lesão ou ofensa a direitos ou interesses difusos, coletivos ou individuais, que, dadas as circunstâncias, 
          ocasionam ou tem potencial para ocasionar dano significativo ou irreversível." required />
        <span>
          Podem sofrer lesão ou ofensa a direitos ou interesses difusos, coletivos ou individuais, que, dadas as circunstâncias,
          ocasionam ou tem potencial para ocasionar dano significativo ou irreversível.
        </span>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se cabível, quais medidas foram adotadas para mitigação dos riscos causados pelo incidente aos titulares?</strong></label>
        <textarea name="methodsAdopted" type="text" className={styles.textAreaInput} />
      </div>

      <h2>Medidas de Segurança, Técnicas e Administrativas, para a Proteção dos Dados Pessoais</h2>

      <label className={styles.textAreaLabel}><strong>Os dados violados estavam protegidos de forma a impossibilitar a identificação de seus titulares?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="wasProtected" type="radio" value="Sim, integralmente protegidos por criptografia / pseudonimização / etc." required/>
          <span>Sim, integralmente protegidos por criptografia / pseudonimização / etc.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="wasProtected" type="radio" value="Sim, parcialmente protegidos por criptografia / pseudonimização / etc." required/>
          <span>Sim, parcialmente protegidos por criptografia / pseudonimização / etc.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="wasProtected" type="radio" value="Não" required />
          <span>Não</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Descreva os meios utilizados para proteger a identidade dos titulares, e a quais tipos de dados foram aplicados:</strong></label>
        <textarea name="protectionDescr" type="text" className={styles.textAreaInput} required/>
      </div>

      <label className={styles.textAreaLabel}><strong>Antes do incidente, quais das seguintes medidas de segurança eram adotadas?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Políticas de segurança da informação e privacidade." />
          <span>Políticas de segurança da informação e privacidade.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Processo de Gestão de Riscos." />
          <span>Processo de Gestão de Riscos.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Registro de incidentes" />
          <span>Registro de incidentes</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="ontrole de acesso físico" />
          <span>Controle de acesso físico</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Controle de acesso lógico" />
          <span>Controle de acesso lógico</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Segregação de rede." />
          <span>Segregação de rede.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Criptografia/Pseudonimização." />
          <span>Criptografia/Pseudonimização.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Cópias de segurança. (backups)" />
          <span>Cópias de segurança. (backups)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Gestão de ativos." />
          <span>Gestão de ativos.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Antivírus" />
          <span>Antivírus</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Firewall" />
          <span>Firewall</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Atualização de Sistemas" />
          <span>Atualização de Sistemas.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Registros de acesso (logs)" />
          <span>Registros de acesso (logs).</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Monitoramento de uso de rede e sistemas." />
          <span>Monitoramento de uso de rede e sistemas.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Múltiplos fatores de autenticação." />
          <span>Múltiplos fatores de autenticação.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Testes de invasão." />
          <span>Testes de invasão.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Plano de resposta a incidentes." />
          <span>Plano de resposta a incidentes.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdopted" type="checkbox" value="Outras" />
          <span>Outras</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Descreva as demais medidas de segurança técnicas e administrativas adotadas antes do incidente: </strong></label>
        <textarea name="tecnicalMethdsDescr" type="text" className={styles.textAreaInput} required/>
      </div>

      <label className={styles.textAreaLabel}><strong>Após o incidente, foi adotada alguma nova medida de segurança?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="políticas de segurança da informação e privacidade." />
          <span>Políticas de segurança da informação e privacidade.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Processo de Gestão de Riscos." />
          <span>Processo de Gestão de Riscos.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Registro de incidentes" />
          <span>Registro de incidentes</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Controle de acesso físico" />
          <span>Controle de acesso físico</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Controle de acesso lógico" />
          <span>Controle de acesso lógico</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Segregação de rede." />
          <span>Segregação de rede.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Criptografia/Pseudonimização." />
          <span>Criptografia/Pseudonimização.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Cópias de segurança. (backups)" />
          <span>Cópias de segurança. (backups)</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Gestão de ativos." />
          <span>Gestão de ativos.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Antivírus" />
          <span>Antivírus</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Firewall" />
          <span>Firewall</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Atualização de Sistemas." />
          <span>Atualização de Sistemas.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Registros de acesso (logs)." />
          <span>Registros de acesso (logs).</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Monitoramento de uso de rede e sistemas." />
          <span>Monitoramento de uso de rede e sistemas.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Múltiplos fatores de autenticação." />
          <span>Múltiplos fatores de autenticação.</span>
        </div>
      </div>

      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Testes de invasão." />
          <span>Testes de invasão.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Plano de resposta a incidentes." />
          <span>Plano de resposta a incidentes.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securityMethodAdoptedAfter" type="checkbox" value="Outras" />
          <span>Outras</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se cabível, descreva as medidas de segurança adicionais adotadas após o incidente:</strong></label>
        <textarea name="tecnicalMethdsDescrAfter" type="text" className={styles.textAreaInput} />
      </div>

      <label className={styles.textAreaLabel}><strong>As atividades de tratamento de dados afetadas estão submetidas a regulações de segurança setoriais?</strong></label>
      <div className={styles.radioRow}>
        <div className={styles.radioRowBox}>
          <input name="securitySector" type="radio" value="Sim" required/>
          <span>Sim.</span>
        </div>

        <div className={styles.radioRowBox}>
          <input name="securitySector" type="radio" value="Não" required/>
          
          <span>Não</span>
        </div>
      </div>

      <div className={styles.textAreaBox}>
        <label className={styles.textAreaLabel}><strong>Se cabível, indique as regulamentações setoriais de segurança aplicáveis às atividades de tratamento de dados afetadas pelo incidente:</strong></label>
        <textarea name="tecnicalMethdsDescrAfter" type="text" className={styles.textAreaInput} />
      </div>

      <div className={styles.advice}>
        <p>Cabe ao agente regulado solicitar à ANPD o sigilo de informações protocoladas nos processos relativas à sua
          atividade empresarial, como dados e informações técnicas, econômico-financeiras, contábeis, operacionais, cuja divulgação possa representar violação a segredo comercial ou a industrial, nos termos do §2º
          Art. 5º do Regulamento do Processo de Fiscalização e do Processo Administrativo Sancionador.</p>
      </div>

      <div className={styles.signature}>
        <p>Declaro, sob as penas da lei, serem verdadeiras as informações prestadas acima. <br />
          ASSINATURA:
        </p>
      </div>

      <button type="submit">Enviar</button>
    </form>
  );
};

export default FormFields;
